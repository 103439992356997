import "./App.css";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { injected } from "./utils/connector";
import { BigNumber, ethers, getDefaultProvider } from "ethers";
import ContractAbi from "./abis/abi.json";
import { whitelist } from "./abis/whitelist";

toast.configure();

require("dotenv").config();
//const NETWORK_NAME = 'rinkeby';
//const INFURA_KEY = '1269a09780f947cfaf218f53c0f34fe4'
// const RPC_URI = 'https://' + NETWORK_NAME + '.infura.io/v3/' + INFURA_KEY;
//const RPC_URI = "https://eth-rinkeby.alchemyapi.io/v2/U9UhJO60-mk4nPQf_7lABFv9ew_XIn6q";
const RPC_URI = "https://eth-mainnet.alchemyapi.io/v2/U9UhJO60-mk4nPQf_7lABFv9ew_XIn6q";
// web3 & ether provider
const web3TestEth = new web3(new web3.providers.HttpProvider(RPC_URI));
const Web3Contract = new web3TestEth.eth.Contract(
  ContractAbi,
  process.env.REACT_APP_NFT_ADDRESS
);

let provider = null;
let ethContract = null;
try {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  ethContract = new ethers.Contract(
    process.env.REACT_APP_NFT_ADDRESS,
    ContractAbi,
    provider.getSigner()
  );
} catch (error) {
  console.log("----", error, "----");
  toast("Please, install a metamask!", { type: "error" });
}

function App() {
  const { chainId, active, activate, deactivate, account } = useWeb3React();
  const validNetwork = chainId === parseInt(process.env.REACT_APP_CHAIN_ID) ? true : false;
  const [mintPrice, setMintPrice] = useState(null);
  const [address, setAddress] = useState(null);
  const [publicPrice, setPublicPrice] = useState(null);
  const [vipPrice, setVipPrice] = useState(null);

  useEffect(async () => {
    checkTokenState(false);
  }, [account])

  useEffect(() => {
    console.log(validNetwork, chainId, parseInt(process.env.REACT_APP_CHAIN_ID), active, account);

    if (validNetwork && active) {
      console.log('---- wallet is activated!');

      checkTokenState(false);

    }
  }, [validNetwork, active]);

  const ConnectWallet = () => {
    console.log('Connect to Wallet');
    if (account) {
      deactivate();
      setMintPrice(null)
    } else {
      activate(injected);
    }
  };

  const Mint = () => {
    if (!validNetwork || !active) {
      console.log('Not active!');
      return;
    }
    checkTokenState(true);
  };

  async function checkTokenState(_withMint) {
    await Web3Contract.methods.tokenStatus().call().then((data) => {
      console.log("token status:", data);
      const tokenStatus = parseInt(data);

      let _addrHash = whitelist[account.toUpperCase()];

      if (tokenStatus === 0) {
        toast("Minting is paused!", { type: "error" });
      } else if (tokenStatus === 1 || _addrHash) {    // vipMint
        getVipPrice(_addrHash, _withMint);
      } else if (tokenStatus === 2) {    // Mint
        getPrice(_withMint);
      }
    })
      .catch((error) => console.log(error));
  }
  useEffect(async () => {
    let price;
    price = await Web3Contract.methods.getVipPrice().call();
    const realPrice = parseInt(price) / 1000000000000000000
    setVipPrice(realPrice);
  })
  useEffect(async () => {
    let price;
    price = await Web3Contract.methods.getPrice().call();
    const realPrice = parseInt(price) / 1000000000000000000
    setPublicPrice(realPrice);
  })
  async function getVipPrice(addrHash, _withMint) {
    await Web3Contract.methods.getVipPrice().call().then((data) => {
      const vipPrice = parseInt(data) / 1000000000000000000;

      console.log("vip mint price(eth):", vipPrice);
      setMintPrice(vipPrice);

      if (_withMint) vipMint(addrHash, vipPrice);
    })
      .catch((error) => console.log(error));
  }

  async function vipMint(addrHash, vipPrice) {
    console.log("---- vip mint ----", (counter * vipPrice).toString());

    const price = ethers.utils.parseEther((counter * vipPrice).toString());
    const balance = await provider.getSigner().getBalance();

    if (price - balance > 0) {
      toast("Insufficient Funds!", { type: "error" });
      return;
    }

    try {
      await ethContract.mintVip(counter, addrHash, { value: price })
        .then((tx) => {
          return tx.wait().then(
            (receipt) => {
              // This is entered if the transaction receipt indicates success
              console.log("receipt", receipt);
              toast("Minted Successfully!", { type: "success" });
              return true;
            },
            (error) => {
              console.log("error", error);
              toast("Minting Fail!", { type: "error" });
            }
          );
        })
        .catch((error) => {
          console.log(error);
          if (error.message.indexOf("signature")) {
            toast("Declined transaction!", { type: "error" });
          } else {
            toast("Transaction Error!", { type: "error" });
          }
        });
    } catch (error) {
      console.log(error);
      toast("Minting Error!", { type: "error" });
    }
  }

  async function getPrice(_withMint) {
    await Web3Contract.methods.getPrice().call().then((data) => {
      const Price = parseInt(data) / 1000000000000000000;

      console.log(" price(eth):", Price);
      setMintPrice(Price);
      if (_withMint) normalMint(Price);
    })
      .catch((error) => console.log(error));
  }

  async function normalMint(Price) {
    console.log("---- mint ----");

    const price = ethers.utils.parseEther((counter * Price).toString());
    const balance = await provider.getSigner().getBalance();

    if (price - balance > 0) {
      toast("Insufficient Funds!", { type: "error" });
      return;
    }

    try {
      await ethContract.mint(counter, { value: price })
        .then((tx) => {
          return tx.wait().then(
            (receipt) => {
              // This is entered if the transaction receipt indicates success
              console.log("receipt", receipt);
              toast("Minted Successfully!", { type: "success" });
              return true;
            },
            (error) => {
              console.log("error", error);
              toast("Minting Fail!", { type: "error" });
            }
          );
        })
        .catch((error) => {
          console.log(error);
          if (error.message.indexOf("signature")) {
            toast("Declined transaction!", { type: "error" });
          } else {
            toast("Transaction Error!", { type: "error" });
          }
        });
    } catch (error) {
      console.log(error);
      toast("Minting Error!", { type: "error" });
    }
  }

  const [counter, setCounter] = useState(1);
  const incrementCounter = () => { if (counter < 5) setCounter(counter + 1); }
  const decrementCounter = () => { if (counter > 1) setCounter(counter - 1); }

  var countDownDate = new Date("Feb 16, 2022 21:00:00 UTC+1").getTime();
  var x = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    document.getElementById("hours").innerHTML = hours;
    document.getElementById("min").innerHTML = minutes;
    document.getElementById("sec").innerHTML = seconds;

    if (distance < 0) {
      clearInterval(x);
      document.getElementById("demo").innerHTML = "EXPIRED";
    }
  }, 1000);

  return (

    <div className="s-background">
      <a className='back-link' href='https://meta3oys.com' target='_blank' rel='noreferrer'>
        <div className='mint-btn'>
          <div className='name-wrapper'>
            <span className='back-link-text'>Back to META3OYS.com</span>
          </div>
        </div>
      </a>
      <div className='container'>

        <div className='mint-box'>

          <div className='connect-wallet'>
            <div className='mint-btn' onClick={() => ConnectWallet()}>
              <div className='name-wrapper sized-btn'>
                <span className='white-border'>{account ? `${account.slice(0, 10)}...${account.slice(account.length - 6, account.length)}`
                  : '👉CONNECT WALLET'}</span>
              </div>
            </div>
          </div>
          <div className='mint-text'>GET YOUR META3OYS</div>
          <div className='mint-container white-border'>
            <div className='counter-item' onClick={decrementCounter}>-</div>
            <div className='counter-number'>{counter}</div>
            <div className='counter-item' onClick={incrementCounter}>+</div>
            <div className='mint-btn' onClick={() => Mint()}>
              <div className='name-wrapper-mint name-wrapper'>
                <span>Mint👈</span>
              </div>
            </div>
          </div>
          {mintPrice ?
            <div className='mint-price'>
              1 META3OY = {mintPrice} <img className='eth-icon' src='https://ethereum.org/static/a110735dade3f354a46fc2446cd52476/f3a29/eth-home-icon.webp' width="10" /> + GAS
            </div>
            :
            <div className='mint-price'>
              1 META3OY = {publicPrice} <img className='eth-icon' src='https://ethereum.org/static/a110735dade3f354a46fc2446cd52476/f3a29/eth-home-icon.webp' width="10" /> or {vipPrice} <img className='eth-icon' src='https://ethereum.org/static/a110735dade3f354a46fc2446cd52476/f3a29/eth-home-icon.webp' width="10" /> (WL) + GAS
            </div>
          }
        </div>
      </div>

    </div>
  );
}

export default App;
